import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import socket from '../services/socket';
import { Package, DollarSign, Ticket } from 'lucide-react';
import '../assets/styles/SelectNumbersPage.css';

const formatTicketNumber = (number) => String(number).padStart(3, '0');

const SelectNumbersPage = () => {
  const [tickets, setTickets] = useState([]);
  const [selectedNumbers, setSelectedNumbers] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activeRaffle, setActiveRaffle] = useState(null);
  const navigate = useNavigate();

  const fetchRaffleAndTickets = async () => {
    try {
      const raffleResponse = await axios.get('https://rifascai.com/api/raffle');
      const raffle = raffleResponse.data;
      setActiveRaffle(raffle);

      const ticketsResponse = await axios.get('https://rifascai.com/api/tickets');
      console.log('Raw tickets from server:', ticketsResponse.data);

      // Enhanced ticket processing with strict status checking
      const processedTickets = ticketsResponse.data
        .filter(ticket => ticket.ticketNumber <= raffle.totalTickets)
        .map(ticket => {
          const statusStr = String(ticket.status || '').toLowerCase().trim();
          const formattedNumber = formatTicketNumber(ticket.ticketNumber);
          
          // Log each ticket's status processing
          console.log(`Processing ticket ${formattedNumber}:`, {
            originalStatus: ticket.status,
            normalizedStatus: statusStr,
            isSold: statusStr === 'sold'
          });
          
          // Strict sold status check
          if (statusStr === 'sold') {
            return {
              ...ticket,
              status: 'sold',
              ticketNumber: formattedNumber
            };
          }
          
          return {
            ...ticket,
            status: ['available', 'reserved'].includes(statusStr) ? statusStr : 'available',
            ticketNumber: formattedNumber
          };
        });

      // Log detailed status breakdown
      const statusCounts = processedTickets.reduce((acc, t) => {
        acc[t.status] = (acc[t.status] || 0) + 1;
        return acc;
      }, {});

      console.log('Processed tickets status breakdown:', statusCounts);
      console.log('Sold tickets:', processedTickets.filter(t => t.status === 'sold'));
      
      setTickets(processedTickets);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching initial data:', error);
      setError('Error al cargar la información de la rifa.');
      setLoading(false);
    }
  };

  // Status verification effect
  useEffect(() => {
    const verifyTicketStatuses = () => {
      const soldTickets = tickets.filter(t => t.status === 'sold');
      console.log('Verifying ticket statuses - Sold tickets found:', soldTickets);

      // Log detailed ticket information
      tickets.forEach(ticket => {
        console.log(`Ticket ${ticket.ticketNumber}: Status = ${ticket.status}, Type = ${typeof ticket.status}`);
      });
    };

    verifyTicketStatuses();
    const intervalId = setInterval(verifyTicketStatuses, 30000);

    return () => clearInterval(intervalId);
  }, [tickets]);

  // Main socket effect
  useEffect(() => {
    const handleTicketStatusChange = (data) => {
      console.log('Ticket status change event received:', data);
      if (!data?.tickets) return;

      const formattedTickets = Array.isArray(data.tickets) 
        ? data.tickets.map(num => formatTicketNumber(num))
        : [formatTicketNumber(data.tickets)];

      setTickets(prevTickets => {
        const updatedTickets = prevTickets.map(ticket => {
          if (ticket.status === 'sold') {
            console.log(`Protecting sold ticket: ${ticket.ticketNumber}`);
            return ticket;
          }

          if (formattedTickets.includes(ticket.ticketNumber)) {
            const newStatus = String(data.status || '').toLowerCase().trim();
            console.log(`Updating ticket ${ticket.ticketNumber} from ${ticket.status} to ${newStatus}`);

            if (newStatus === 'sold') {
              console.log(`Marking ticket ${ticket.ticketNumber} as sold`);
              return {
                ...ticket,
                status: 'sold',
                soldAt: new Date().toISOString()
              };
            }

            return {
              ...ticket,
              status: newStatus,
              reservedAt: newStatus === 'reserved' ? new Date().toISOString() : null,
              userId: newStatus === 'reserved' ? data.userId : null
            };
          }
          return ticket;
        });

        console.log('Updated tickets:', updatedTickets);
        return updatedTickets;
      });
    };

    const handlePaymentRejected = (data) => {
      console.log('Payment rejected event received:', data);
      if (!data?.tickets) return;

      const formattedTickets = Array.isArray(data.tickets) 
        ? data.tickets.map(num => formatTicketNumber(num))
        : [formatTicketNumber(data.tickets)];

      setTickets(prevTickets => {
        return prevTickets.map(ticket => {
          if (ticket.status === 'sold') return ticket;
          if (formattedTickets.includes(ticket.ticketNumber)) {
            return {
              ...ticket,
              status: 'available',
              reservedAt: null,
              userId: null
            };
          }
          return ticket;
        });
      });

      setSelectedNumbers(prev => 
        prev.filter(num => !formattedTickets.includes(formatTicketNumber(num)))
      );
    };

    const handleTicketsReleased = (data) => {
      console.log('Tickets released event received:', data);
      if (!data?.tickets) return;

      const formattedTickets = Array.isArray(data.tickets) 
        ? data.tickets.map(num => formatTicketNumber(num))
        : [formatTicketNumber(data.tickets)];

      setTickets(prevTickets => {
        return prevTickets.map(ticket => {
          if (ticket.status === 'sold') return ticket;
          if (formattedTickets.includes(ticket.ticketNumber)) {
            return {
              ...ticket,
              status: 'available',
              reservedAt: null,
              userId: null
            };
          }
          return ticket;
        });
      });
    };

    fetchRaffleAndTickets();

    socket.on('ticket_status_changed', handleTicketStatusChange);
    socket.on('payment_rejected', handlePaymentRejected);
    socket.on('tickets_released', handleTicketsReleased);

    return () => {
      socket.off('ticket_status_changed', handleTicketStatusChange);
      socket.off('payment_rejected', handlePaymentRejected);
      socket.off('tickets_released', handleTicketsReleased);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleNumberClick = (number) => {
    setError(null);
    const ticket = tickets.find(t => t.ticketNumber === number);
    if (!ticket || ticket.status !== 'available') return;

    setSelectedNumbers(prevNumbers => {
      const updated = prevNumbers.includes(number)
        ? prevNumbers.filter(n => n !== number)
        : [...prevNumbers, number];
      return updated.sort((a, b) => a - b);
    });
  };

  const handleContinue = () => {
    if (!selectedNumbers.length) {
      setError("Por favor, seleccione al menos un ticket para continuar.");
      return;
    }

    navigate('/payment-method', { 
      state: { 
        selectedNumbers: [...selectedNumbers].sort((a, b) => a - b),
        raffleId: activeRaffle._id
      } 
    });
  };

  const createNumberGrid = () => {
    if (!activeRaffle) return [];
    
    return Array.from({ length: activeRaffle.totalTickets }, (_, i) => {
      const formattedNumber = formatTicketNumber(i);
      const existingTicket = tickets.find(t => t.ticketNumber === formattedNumber);
      
      if (!existingTicket) {
        return {
          ticketNumber: formattedNumber,
          status: 'available'
        };
      }

      const currentStatus = String(existingTicket.status || '').toLowerCase().trim();
      console.log(`Grid creating ticket ${formattedNumber}, status: ${currentStatus}`);

      if (currentStatus === 'sold') {
        return {
          ticketNumber: formattedNumber,
          status: 'sold'
        };
      }

      return {
        ticketNumber: formattedNumber,
        status: ['reserved', 'available'].includes(currentStatus) ? currentStatus : 'available'
      };
    });
  };

  // Your existing render logic remains the same...

  return (
    <div className="select-numbers-page">
      {/* Loading state */}
      {loading ? (
        <div className="loading-container">
          <div className="loading-spinner"></div>
          <p>Cargando información de la rifa...</p>
        </div>
      ) : !activeRaffle ? (
        <div className="no-raffle-card">
          <Package size={48} />
          <h2>No hay Rifa Activa</h2>
          <p>No hay rifa activa en este momento. Por favor, vuelva más tarde.</p>
        </div>
      ) : (
        <>
          <div className="raffle-info-card">
            <img 
              src={activeRaffle.productImage} 
              alt={activeRaffle.productName}
              className="raffle-image"
            />
            <div className="raffle-details">
              <h2>{activeRaffle.productName}</h2>
              <p className="description">{activeRaffle.description}</p>
              
              <div className="stats-grid">
                <div className="stat-item">
                  <DollarSign size={20} />
                  <div className="stat-info">
                    <span className="label">Precio por Ticket</span>
                    <span className="value">${activeRaffle.price}</span>
                  </div>
                </div>
                
                <div className="stat-item">
                  <Ticket size={20} />
                  <div className="stat-info">
                    <span className="label">Tickets Disponibles</span>
                    <span className="value">
                      {activeRaffle.totalTickets - (activeRaffle.soldTickets || 0)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {error && <div className="error-message">{error}</div>}
          
          <div className="numbers-selection-card">
            <h3>Selecciona Tus Números</h3>
            <div className="numbers-grid">
              {createNumberGrid().map((ticket) => {
                const status = String(ticket.status || '').toLowerCase().trim();
                const isDisabled = status === 'reserved' || status === 'sold';
                const buttonClass = `number-button ${status} ${
                  selectedNumbers.includes(ticket.ticketNumber) ? 'selected' : ''
                }`;

                return (
                  <button
                    key={ticket.ticketNumber}
                    className={buttonClass}
                    onClick={() => handleNumberClick(ticket.ticketNumber)}
                    disabled={isDisabled}
                    title={status !== 'available' ? `Número ${status === 'sold' ? 'vendido' : 'reservado'}` : ''}
                  >
                    {ticket.ticketNumber}
                  </button>
                );
              })}
            </div>
          </div>

          <div className="sticky-summary">
            <div className="summary-content">
              <div className="summary-details">
                <p className="total-amount">
                  Monto Total: ${selectedNumbers.length * activeRaffle.price}
                </p>
                <p className="selected-numbers">
                  Números Seleccionados: {selectedNumbers.length ? selectedNumbers.join(', ') : 'Ninguno'}
                </p>
              </div>
              <button 
                onClick={handleContinue} 
                disabled={selectedNumbers.length === 0}
                className="continue-button"
              >
                Continuar
              </button>
            </div>
          </div>
        </>
      )}

      {process.env.NODE_ENV === 'development' && (
        <div style={{ position: 'fixed', bottom: '10px', right: '10px', display: 'flex', gap: '10px' }}>
          <button 
            onClick={fetchRaffleAndTickets}
            style={{ 
              padding: '5px 10px',
              background: '#ddd',
              border: '1px solid #999',
              borderRadius: '4px',
              cursor: 'pointer'
            }}
          >
            Refresh Tickets
          </button>
          <button 
            onClick={() => {
              console.log('Current tickets state:', tickets);
              const soldTickets = tickets.filter(t => t.status === 'sold');
              console.log('Sold tickets:', soldTickets);
            }}
            style={{ 
              padding: '5px 10px',
              background: '#ddd',
              border: '1px solid #999',
              borderRadius: '4px',
              cursor: 'pointer'
            }}
          >
            Log Tickets
          </button>
        </div>
      )}
    </div>
  );
};

export default SelectNumbersPage;