// src/components/layouts/AdminLayout.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import {
  Package,
  Home,
  DollarSign,
  Plus,
  Calendar,
  CheckCircle,
  LogOut,
  MoreHorizontal
} from 'lucide-react';
import '../../assets/styles/adminSections/AdminLayout.css';

const AdminLayout = ({ onLogout }) => {
  const [isCollapsed] = useState(false);
  const [showMoreMenu, setShowMoreMenu] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const navigationItems = [
    {
      path: '/admin/dashboard',
      icon: <Home size={20} />,
      label: 'Dashboard',
      color: 'purple' // Custom color for active state
    },
    {
      path: '/admin/pending-payments',
      icon: <DollarSign size={20} />,
      label: 'Pagos Pendientes'
    },
    {
      path: '/admin/confirmed-payments',
      icon: <CheckCircle size={20} />,
      label: 'Pagos Confirmados'
    },
    {
      path: '/admin/create-raffle',
      icon: <Plus size={20} />,
      label: 'Nueva Rifa'
    },
    {
      path: '/admin/active-raffles',
      icon: <Calendar size={20} />,
      label: 'Rifas Activas'
    }
  ];

  // Split navigation items
  const mainNavItems = navigationItems.slice(0, 3);
  const moreNavItems = navigationItems.slice(3);

  const isActive = (path) => location.pathname === path;

  const handleNavigation = (path) => {
    navigate(path);
    setShowMoreMenu(false);
  };

  const handleMoreClick = (e) => {
    e.stopPropagation();
    setShowMoreMenu(!showMoreMenu);
  };

  // Close more menu when clicking outside
  useEffect(() => {
    const handleClickOutside = () => setShowMoreMenu(false);
    
    if (showMoreMenu) {
      document.addEventListener('click', handleClickOutside);
    }
    
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [showMoreMenu]);

  return (
    <div className="admin-layout">
      {/* Desktop Sidebar */}
      <aside className={`sidebar ${isCollapsed ? 'collapsed' : ''}`}>
        <div className="logo-container">
          <Package className="nav-item-icon" />
          {!isCollapsed && <span>Admin Panel</span>}
        </div>

        <nav className="nav-section">
          {navigationItems.map((item) => (
            <button
              key={item.path}
              className={`nav-item ${isActive(item.path) ? 'active' : ''}`}
              onClick={() => handleNavigation(item.path)}
              style={isActive(item.path) && item.color ? { backgroundColor: `var(--${item.color})` } : {}}
            >
              {item.icon}
              <span className="nav-item-text">{item.label}</span>
            </button>
          ))}
        </nav>

        <div className="user-menu">
          <button
            className="user-menu-button"
            onClick={onLogout}
          >
            <LogOut size={20} />
            <span className="nav-item-text">Cerrar Sesión</span>
          </button>
        </div>
      </aside>

      {/* Mobile Bottom Navigation */}
      <div className="bottom-nav">
        <div className="bottom-nav-items">
          {/* Main Navigation Items */}
          {mainNavItems.map((item) => (
            <button
              key={item.path}
              className={`bottom-nav-item ${isActive(item.path) ? 'active' : ''}`}
              onClick={() => handleNavigation(item.path)}
            >
              {item.icon}
              <span>{item.label}</span>
            </button>
          ))}

          {/* More Menu */}
          <div className="bottom-nav-more">
            <button
              className={`bottom-nav-item ${moreNavItems.some(item => isActive(item.path)) ? 'active' : ''}`}
              onClick={handleMoreClick}
            >
              <MoreHorizontal size={20} />
              <span>Más</span>
            </button>

            {showMoreMenu && (
              <div className="more-menu">
                {moreNavItems.map((item) => (
                  <button
                    key={item.path}
                    className={`more-menu-item ${isActive(item.path) ? 'active' : ''}`}
                    onClick={() => handleNavigation(item.path)}
                  >
                    {item.icon}
                    <span>{item.label}</span>
                  </button>
                ))}
                <div className="more-menu-divider" />
                <button 
                  className="more-menu-item"
                  onClick={onLogout}
                >
                  <LogOut size={20} />
                  <span>Cerrar Sesión</span>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      <main className={`main-content ${isCollapsed ? 'expanded' : ''}`}>
        <Outlet />
      </main>
    </div>
  );
};

export default AdminLayout;